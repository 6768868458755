<template>
    <div>
      <h1>Topics</h1>
      <input type="text" v-model="search" placeholder="Search...">
      <ul>
        <li v-for="item in filteredItems" :key="item.id">
          <h2>{{ item.value }}</h2>
          <p>{{ item.sourceNote }}</p>
        </li>
      </ul>
    </div>
  </template>

  <script>
  import axios from 'axios';

  export default {
    perPage: 3,
    currentPage: 1,
    name: 'TopicsData',
    data() {
      return {
        items: [],
        pageOfItems: [],
        search: "",
        filter: "",
      };
    },
    computed: {
      filteredItems() {
        return this.items.filter((item) => {
          return (
            item.value.toLowerCase().includes(this.search.toLowerCase()) ||
            item.sourceNote.toLowerCase().includes(this.search.toLowerCase()) &&
            (this.filter === "")
          );
        });
      },
    },
    mounted() {
      axios
      .get('http://52.139.170.67:5000/topic')
      .then(response => {
        response.data.forEach(element => {
          this.items.push({id: element[2], value: element[1], sourceNote: element[0]});
        });
      });
    },
    methods: {
        onChangePage(pageOfItems) {
            this.pageOfItems = pageOfItems;
        }
    }
  };
  </script>

<style scoped>
h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
input[type="text"] {
  padding: 0.5rem;
  font-size: 1.2rem;
  margin-right: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  width: 50%;
  max-width: 300px;
}
select {
  padding: 0.5rem;
  font-size: 1.2rem;
  margin-right: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}
ul {
  list-style: none;
  padding: 0;
  margin-top: 4%;
}
li {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}
h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
p {
  margin: 0;
  font-size: 1.2rem;
}
</style>